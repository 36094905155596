export const parseRecentlyViewedDate = (date: Date) => {
  const isToday = date.toDateString() === new Date().toDateString()
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  const formattedDate = date.toLocaleString('en-US', {
    timeZone,
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    ...(!isToday && { month: 'numeric' }),
    ...(!isToday && { day: 'numeric' }),
    ...(!isToday && { year: 'numeric' }),
  })

  return (isToday ? 'Today at ' : '') + formattedDate.split(',').join(' at ')
}

export const parseStringDate = (dateString: string, options:{
  showTime?: boolean
  delimiter?: string
}={
  showTime: false,
}) => {
  const parsedDate = new Date(dateString)

  const formattedDate = parsedDate.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    ...(options.showTime && { hour: 'numeric' }),
    ...(options.showTime && { minute: 'numeric' }),
  })

  if (options.delimiter) {
    return formattedDate.split('/').join(options.delimiter)
  }
  return formattedDate
}